import React from "react"
import { Link } from "gatsby"
import Layout from "components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="notfound-page design-style">
      <div className="contents">
        <div className="fw-b tx-center page-title">
          <h1 className="title e">404 Not Found</h1>
          <p className="sub-title">お探しのページが見つかりません</p>
        </div>
        <div className="details tx-center">
          <p className="details-text">
            お探しのページはURLが間違っているか、削除された可能性があります。
          </p>
          <Link to="/" className="back-top">
            トップページへ
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
